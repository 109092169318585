import React from 'react'
import { useGetLinks } from '../../hooks/graphql/links'
import Spinner from '../loader.gif'
import copyIcon from '../copy_icon.png'

export const Links: React.FC = () => {
  const { data: links, isLoading } = useGetLinks()

  const SpinnerComponent = () => (
    <div className="h-screen -ml-6 -mt-6 w-screen bg-white z-20 fixed flex items-center justify-center">
      <div className="flex items-center justify-center flex-wrap max-w-48">
        <img className="flex w-24 -mt-16" src={Spinner} alt="" />
        <br />
        <div className="w-full text-center mx-auto flex justify-center font-body mt-4 text-base text-blue-400 leading-5">
          {'Daten werden geladen.'}
        </div>
      </div>
    </div>
  )

  return isLoading ? (
    <SpinnerComponent />
  ) : (
    <div className='h-fit w-full mt-16 mx-16 flex flex-col gap-4'>
      <div>
        <div className='text-xl font-bold py-4'>Aposhpäre Links</div>
        <div className="flex flex-row flex-wrap justify-between">
          {links?.map((link) => (
            <div
              key={link.id}
              className="flex items-center rounded-xl shadow-md justify-center w-320px 2xl:w-420px h-280px 2xl:h-320px p-8 mb-8 border-black"
            >
              <div className="max-w-md flex flex-col items-center h-full justify-between">
              <h2 className="text-xl 2xl:text-2xl font-medium text-center m-8">{link.name}</h2>            
                {link.name === 'Handbuch' && !!link.username && (
                    <div className="flex flex-row gap-4 text-sm 2xl:text-base pb-2">
                      <div
                        className="text-gray-650"
                      >
                        {`Username:  ${link.username}`}
                      </div>
                    </div>
                )}
                {link.name === 'Handbuch' && (
                  <button
                    className="bg-gray-150 2xl:w-320px w-220px rounded-2xl py-3 px-8 flex items-center justify-center mb-2"
                    onClick={() => {
                      navigator.clipboard.writeText(`${link.password}`)
                    }}
                  >
                    <div className="flex flex-row gap-4 text-sm 2xl:text-base">
                      <div className="text-gray-650">{`Passwort:  ${link.password}`}</div>
                      <img src={copyIcon} alt="Copy Icon" className="h-20px w-fit" />
                    </div>
                  </button>
                )}
                <a href={link.link} target="_blank" rel="noopener noreferrer" className="block">
                  <button className="bg-blue-600 w-220px 2xl:w-320px text-white font-medium text-sm 2xl:text-base rounded-2xl py-3 px-8 flex items-center justify-center hover:bg-blue-700 transition-colors duration-300">
                    Jetzt öffnen
                    <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div>
        <div className='text-xl font-bold py-4'>Aposphäre Campus Schulungen</div>
        <div className="flex flex-row flex-wrap justify-between">
          {links?.map((link) => (
            <div
              key={link.id}
              className="flex items-center border border-black rounded-xl justify-center w-320px 2xl:w-420px h-280px 2xl:h-320px p-8 mb-8"
              style={{ backgroundImage: 'url(https://picsum.photos/300/200)' }}
            >
              <div className="max-w-md flex flex-col items-center h-full justify-between">
                <h2 className="text-xl 2xl:text-2xl font-medium text-center m-8 text-white">{link.name}</h2>
                <a href={link.link} target="_blank" rel="noopener noreferrer" className="block">
                  <button className="bg-blue-600 w-220px 2xl:w-320px text-white font-medium text-sm 2xl:text-base rounded-2xl py-3 px-8 flex items-center justify-center hover:bg-blue-700 transition-colors duration-300">
                    Jetzt öffnen
                    <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  )
}
